import "./styles.scss"

import React from "react"
import dayjs from "dayjs"
import { Link } from "gatsby"

const ArticleSingleTail = ({ title, href, img, date }) => {
  return (
    <Link
      to={href}
      className={`article-single-tail ${
        !!date ? "article-single-tail--with-date" : ""
      } `}
    >
      <div className="article-single-tail__image">
        <img src={img?.sourceUrl} alt={img?.altText} />
        <p>{title}</p>
        {!!date && (
          <div className="article-single-tail__image__date">
            <span>Data publikacji:</span>
            <strong>{dayjs(date)?.locale("pl")?.format("DD MMMM YYYY")}</strong>
          </div>
        )}
      </div>
    </Link>
  )
}

export default ArticleSingleTail
